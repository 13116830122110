import * as React from 'react';
import styled from 'styled-components';
import { GrammarSearchComponent } from './GrammarSearchComponent';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { ButtonIconPrimarySize } from '@doltech/ui/lib/figma/Button/ButtonIconPrimarySize/ButtonIconPrimarySize';
import { ButtonIconPrimaryStyles } from '@doltech/ui/lib/figma/Button/ButtonIconPrimaryStyles/ButtonIconPrimaryStyles';
import { IconSystemV2ArrowArrowLeftLine } from '@doltech/icons/SystemV2/IconSystemV2ArrowArrowLeftLine';
import { LAYER } from '@doltech/utils/lib/constants';
import { shadows } from '@doltech/ui/lib/figma/shadows';

const Container = styled.div`
  width: 100%;
  height: 64px;
  position: fixed;
  z-index: ${LAYER.LAYER10};
  left: 0;
  right: 0;
  background: ${colorsV2.white100};
  box-shadow: ${shadows.zIndex1};
  top: 0;
  padding: 9px 16px;

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content;
  gap: 16px;
  align-items: center;
`;

interface GrammarSearchBarOpenProps {
  onClick: () => void;
  className?: string;
}

const GrammarSearchBarOpen = ({ onClick, className }: GrammarSearchBarOpenProps) => {
  return (
    <Container className={className}>
      <ButtonIconPrimaryStyles
        color="white"
        buttonStyle="outline"
        content={
          <ButtonIconPrimarySize size="x-large" icon={<IconSystemV2ArrowArrowLeftLine />} />
        }
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      />
      <GrammarSearchComponent />
    </Container>
  );
};

export default GrammarSearchBarOpen;
